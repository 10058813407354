import React from "react";

export default function() {
  return (
    <div>
      <ol>
        {/* <!-- I. NOTICE OF HEALTH INFORMATION PRIVACY PRACTICES --> */}
        <li>
          <p>
            <span class="inline_heading">
              Notice of Health Information Privacy Practices
            </span>
          </p>

          <p>
            The Healthcare Professionals who deliver Services through SwyftDoc
            practice within a group of independently owned professional
            practices collectively known as "SwyftDoc Professionals". This
            section of our Privacy Policy (<strong>Section I</strong>, "
            <strong>Notice of Health Information Privacy Practices</strong>" or
            "<strong>Notice</strong>" ) describes how SwyftDoc Professionals
            (and Swyft Health, LLC., when acting on behalf of SwyftDoc
            Professionals) may use and disclose health information about you and
            how you can access this information. Please review it carefully.
          </p>

          <h3>How is patient privacy protected?</h3>
          <p>
            As the healthcare professionals providing online medical and
            clinical services through SwyftDoc (the "
            <strong>Healthcare Professionals</strong>", "<strong>us</strong>", "
            <strong>we</strong>", "<strong>our</strong>"), we understand that
            information about you and your health is personal. Because of this,
            we strive to maintain the confidentiality of your health
            information. We continuously seek to safeguard that information
            through administrative, physical and technical means, and otherwise
            abide by applicable federal and state guidelines.
          </p>

          <h3>How do we use and disclose health information?</h3>
          <p>
            We use and disclose your health information for the normal business
            activities that the law sees as falling in the categories of
            treatment, payment and healthcare operations. Below we provide
            examples of those activities, although not every use or disclosure
            falling within each category is listed:
          </p>

          <ul>
            <li>
              <strong>Treatment</strong> – We keep a record of the health
              information you provide us. This record may include your test
              results, diagnoses, medications, your response to medications or
              other therapies, and information we learn about your medical
              condition through the online Services. We may disclose this
              information so that other doctors, nurses, and entities such as
              laboratories can meet your healthcare needs.
            </li>
            <li>
              <strong>Payment</strong> – We document the services and supplies
              you receive when we are providing care to you so that you, your
              insurance company or another third party can pay us. We may tell
              your health plan about upcoming treatment or services that require
              prior approval by your health plan.
            </li>
            <li>
              <strong>Health care Operations</strong> – Health information is
              used to improve the services we provide, to train staff, for
              business management, quality assessment and improvement, and for
              customer service. For example, we may use your health information
              to review our treatment and services and to evaluate the
              performance of our staff in caring for you.
            </li>
          </ul>

          <p class="bullet_prompt">
            We may also use your health information to:{" "}
          </p>
          <ul>
            <li>
              Comply with federal, state or local laws that require disclosure.
            </li>
            <li>
              Assist in public health activities such as tracking diseases or
              medical devices.{" "}
            </li>
            <li>Inform authorities to protect victims of abuse or neglect. </li>
            <li>
              Comply with federal and state health oversight activities such as
              fraud investigations.{" "}
            </li>
            <li>
              Respond to law enforcement officials or to judicial orders,
              subpoenas or other process.{" "}
            </li>
            <li>
              Inform coroners, medical examiners and funeral directors of
              information necessary for them to fulfill their duties.
            </li>
            <li>Facilitate organ and tissue donation or procurement.</li>
            <li>
              Conduct research following internal review protocols to ensure the
              balancing of privacy and research needs.{" "}
            </li>
            <li>Avert a serious threat to health or safety.</li>
            <li>
              Assist in specialized government functions such as national
              security, intelligence and protective services.{" "}
            </li>
            <li>
              Inform military and veteran authorities if you are an armed forces
              member (active or reserve).
            </li>
            <li>Inform a correctional institution if you are an inmate. </li>
            <li>
              Inform workers' compensation carriers or your employer if you are
              injured at work.
            </li>
            <li>Recommend treatment alternatives.</li>
            <li>Tell you about health-related products and services.</li>
            <li>
              Communicate within our organization for treatment, payment, or
              healthcare operations.
            </li>
            <li>
              Communicate with other providers, health plans, or their related
              entities for their treatment or payment activities, or health care
              operations activities relating to quality assessment and
              improvement, care coordination and the qualifications and training
              of healthcare professionals;{" "}
            </li>
            <li>
              Provide information to other third parties with whom we do
              business, such as a record storage provider. However, you should
              know that in these situations, we require third parties to provide
              us with assurances that they will safeguard your information.{" "}
            </li>
            <li>
              We may also use or disclose your personal or health information
              for operational purposes. For example, we may communicate with
              individuals involved in your care or payment for that care, such
              as friends and family and send appointment reminders.{" "}
            </li>
          </ul>

          <p>
            All other uses and disclosures, not previously described, may only
            be done with your written authorization. We will also obtain your
            authorization before we use or disclose your health information for
            marketing purposes or before we would sell your information. You may
            revoke your authorization at any time; however, this will not affect
            prior uses and disclosures. In some cases state law may require that
            we apply extra protections to some of your health information.{" "}
          </p>

          <h3>What are the Healthcare Professional's Responsibilities?</h3>
          <p class="bullet_prompt">We are required by law to: </p>
          <ul>
            <li>Maintain the privacy of your health information.</li>
            <li>Provide this Notice of our duties and privacy practices. </li>
            <li>Abide by the terms of the Notice currently in effect. </li>
            <li>
              Tell you if there has been a breach that compromises your health
              information.
            </li>
          </ul>

          <p>
            We reserve the right to change our privacy practices, and make the
            new practices effective for all the information we maintain. Revised
            notices will be posted on the SwyftDoc website and mobile
            application.{" "}
          </p>

          <h3>Do you have any Federal Rights?</h3>
          <p class="bullet_prompt">The law entitles you to:</p>
          <ul>
            <li>
              Inspect and copy certain portions of your health information. We
              may deny your request under limited circumstances. You may request
              that we provide your health records to you in an electronic
              format.
            </li>
            <li>
              Request amendment of your health information if you feel the
              health information is incorrect or incomplete. However, under
              certain circumstances we may deny your request.
            </li>
            <li>
              Receive an accounting of certain disclosures of your health
              information made for the prior six (6) years, although this
              excludes disclosures for treatment, payment, and health care
              operations. (Fees may apply to this request).
            </li>
            <li>
              Request that we restrict how we use or disclose your health
              information. However, we are not required to agree with your
              requests, unless you request that we restrict information provided
              to a payor, the disclosure would be for the payor's payment or
              healthcare operations, and you have paid for the health care
              services completely out of pocket.{" "}
            </li>
            <li>
              Request that we communicate with you at a specific telephone
              number or address.
            </li>
            <li>
              Obtain a paper copy of this notice even if you receive it
              electronically.
            </li>
          </ul>

          <p>We may ask that you make some of these requests in writing. </p>

          <h3>What if I have a Complaint? </h3>
          <p>
            If you believe that your privacy has been violated, you may file a
            complaint with us or with the Secretary of Health and Human Services
            in Washington, D.C. We will not retaliate or penalize you for filing
            a complaint with the facility or the Secretary. To file a complaint
            with us or receive more information contact:
          </p>

          <div class="contact_info">
            <p>Phone: (888) 848-0103</p>
            <p>
              Email:{" "}
              <a href="mailto:compliancehotline@swyftdoc.com" target="_blank">
                compliancehotline@swyftdoc.com
              </a>
            </p>
            <p>
              Address: 241 Crosscreek Terrace, San Diego, CA 92131
            </p>
          </div>

          <p>
            To file a complaint with the Secretary of Health and Human Services
            write to 200 Independence Ave., S.E., Washington, D.C. 20201, call
            1-800-537-7697, or file an online complaint at{" "}
            <a
              href="https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf"
              target="_blank"
            >
              https://ocrportal.hhs.gov/ocr/smartscreen/main.jsf
            </a>
            .
          </p>

          <h3>Who Will Follow This Notice?</h3>
          <p class="bullet_prompt">
            This Notice describes the health care practices of:
          </p>
          <ul>
            <li>
              Any Healthcare Professional authorized to access and/or enter
              information into your health record;
            </li>
            <li>
              All departments and units of SwyftDoc and SwyftDoc Professionals
              through which online health services are provided; and
            </li>
            <li>All affiliates and volunteers.</li>
          </ul>
        </li>

        {/* <!-- II. SITE PRIVACY POLICY --> */}
        <li>
          <p>
            <span class="inline_heading">SwyftDoc Site Privacy Policy</span>
          </p>
          <h3>Introduction.</h3>
          <p>
            This Section of our Privacy Policy (Section II, "
            <strong>Site Privacy Policy</strong>") explains how we collect, use,
            and disclose information from and/or about you when you use the Site
            or the Services.
          </p>

          <p>
            THE SITE WILL BE COLLECTING AND TRANSMITTING PERSONAL, MEDICAL AND
            HEALTH-RELATED INFORMATION ABOUT YOU. BY USING THE SITE, YOU AGREE
            THAT WE CAN COLLECT AND USE YOUR PERSONAL AND OTHER INFORMATION AS
            DESCRIBED IN THIS SITE PRIVACY POLICY. IF YOU DO NOT AGREE, PLEASE
            DO NOT USE THE SITE.
          </p>

          <h3>Important Definitions.</h3>
          <p>
            When we use the term “<strong>Personal Information</strong>” in this
            Privacy Policy, we mean information about you that is personally
            identifiable to you, such as your contact information (e.g., name,
            address, email address, or telephone number), personally
            identifiable health or medical information (“
            <strong>Health Information</strong>”), and any other non-public
            information that is associated with such information. When we use
            the term “<strong>De-Identified Information</strong>”, we mean
            information that is neither used nor intended to be used to
            personally identify an individual. When we use the term “
            <strong>Cookies</strong>”, we mean the small pieces of information
            that a Site sends to your browser while you are viewing a website.
            Lastly, when we use the term “Pixel”, we mean the HTML code snippet
            that may be embedded in certain parts of the Site to collect
            information about the device that you use to access the Site and
            your use of the Site that may be shared with third parties in
            accordance with this Privacy Policy.
          </p>

          <h3>Children under age 18.</h3>
          <p>
            We do not knowingly allow individuals under the age 18 to create
            Accounts that allow access to our Site.
          </p>

          <h3>The Personal information we collect or maintain may include:</h3>
          <ul>
            <li>
              Your name, age, email address, username, password, and other
              registration information.{" "}
            </li>
            <li>
              Health Information that you provide us, which may include
              information or records relating to your medical or health history,
              health status and laboratory testing results, diagnostic images,
              and other health related information.{" "}
            </li>
            <li>
              Health information about you prepared or obtained by the
              Healthcare Professionals(s) who provide clinical services through
              the Site such as medical and therapy records, treatment and
              examination notes, and other health related information.{" "}
            </li>
            <li>
              Billing information that you provide us, such as credit card
              information, or that we receive from a health plan, employer or
              other provider of healthcare benefits on your behalf.
            </li>
            <li>
              Information about the computer or mobile device you are using,
              such as what Internet browser you use, the kind of computer or
              mobile device you use, and other information about how you use the
              Site.{" "}
            </li>
            <li>
              Information from Apple HealthKit, to the extent you choose to sync
              your HealthKit data with us. It is used by our healthcare
              professionals and clinical support staff for purposes related to
              clinical care and healthcare management. We do not use or disclose
              HealthKit data for advertising, marketing, or other use-based data
              mining purposes other than improving health management.
            </li>
            <li>
              Other information you input into the Site or related services.{" "}
            </li>
          </ul>

          <h3>
            We may use your Personal Information for the following purposes{" "}
            <span class="inline_italic">
              (subject to the restrictions relating to the use of Health
              Information described in Section I):
            </span>
          </h3>
          <ul>
            <li>To provide you with the Services.</li>
            <li>
              To improve healthcare quality through the performance of quality
              reviews and similar activities.{" "}
            </li>
            <li>
              To create De-identified Information such as aggregate statistics
              relating to the use of the Services.{" "}
            </li>
            <li>To notify you when Site updates are available. </li>
            <li>To market and promote the Site and the Services to you. </li>
            <li>
              To fulfill any other purpose for which you provide us Personal
              Information.{" "}
            </li>
            <li>
              For the purposes described in Section I relating to the use of
              Health Information.{" "}
            </li>
            <li>For any other purpose for which you give us authorization.</li>
          </ul>

          <h3>
            We may also disclose Personal Information that we collect or you
            provide{" "}
            <span class="inline_italic">
              (subject to the restrictions relating to the use of Health
              Information described in Section I):
            </span>
          </h3>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To contractors, service providers and other third parties we use
              to support our business and who are bound by contractual
              obligations to keep personal information confidential.
            </li>
            <li>
              As required by law, which can include providing information as
              required by a court order.
            </li>
            <li>
              When we believe in good faith that disclosure is necessary to
              protect your safety or the safety of others, to protect our
              rights, to investigate fraud, or to respond to a government
              request.
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution or other
              sale or transfer of some or all of SwyftDoc's assets, whether as a
              going concern or as part of bankruptcy, liquidation or similar
              proceeding, in which Personal Information maintained by the Site
              is among the assets transferred.
            </li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.
            </li>
          </ul>

          <h3>Information We Collect via Technology.</h3>
          <p>
            As you use the Site or the Services, certain information may be
            passively collected by Cookies, navigational data like Uniform
            Resource Locators (URLs) and third party tracking services,
            including:
          </p>
          <ul>
            <li>
              <strong>Site Activity Information</strong>. We may keep track of
              some of the actions you take on the Site, such as the content of
              searches you perform on the Site.{" "}
            </li>
            <li>
              <strong>Access Device and Browser Information</strong>. When you
              access the Site from a computer or other device, we may collect
              anonymous information from that device, such as your Internet
              protocol address, browser type, connection speed and access times
              (collectively, "Anonymous Information").{" "}
            </li>
            <li>
              <strong>Cookies</strong>. We may use both session Cookies (which
              expire once you close your web browser) and persistent Cookies to
              make the Site and Services easier to use, to make our advertising
              better, and to protect both you and SwyftDoc. You can instruct
              your browser, by changing its options, to stop accepting Cookies
              or to prompt you before accepting a Cookie from the websites you
              visit. If you do not accept Cookies, however, you will not be able
              to stay logged in to the Site. We may also use Pixels to make the
              Site and Services easier to use and to make our advertising better
              by, for example, summarizing usage patterns. We presently do not
              honor "Do Not Track" requests across all parts of our Site.{" "}
            </li>
            <li>
              <strong>Real-Time Location</strong>. Certain features of the Site
              use GPS technology to collect real-time information about the
              location of your device so that the Site can connect you to a
              Healthcare Professional who is licensed or authorized to provide
              Services in the state where you are located. When accessing Google
              Maps services on our Site, you are agreeing to Google's{" "}
              <a
                href="https://developers.google.com/maps/terms"
                target="_blank"
              >
                Terms of Service
              </a>{" "}
              and{" "}
              <a
                href="https://policies.google.com/privacy?hl=en&gl=us"
                target="_blank"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <strong>Mobile Services</strong>. We may collect non-personal
              information from your mobile device or computer. This information
              is generally used to help us deliver the most relevant information
              to you. Examples of information that may be collected and used
              include how you use the application(s) and information about the
              type of device or computer you use. In addition, in the event our
              application(s) crashes on your mobile device we will receive
              information about your mobile device model software version and
              device carrier, which allows us to identify and fix bugs and
              otherwise improve the performance of our application(s).{" "}
            </li>
            <li>
              <strong>Analytics Tools</strong>. We use tools such as Google
              Analytics, AppsFlyer and Mixpanel to help analyze how users use
              the Site. Such third parties may use cookies, APIs, and SDKs in
              our services to enable them to collect and analyze user and device
              related data and information on our behalf. Google Analytics,
              AppsFlyer, and Mixpanel use Cookies to collect information such as
              how often users visit the Site, what pages they visit, and what
              other sites they used prior to coming to the Site. We use the
              information we get to improve our Site and sServices. Although
              Google Analytics plants a persistent Cookie on your web browser to
              identify you as a unique user the next time you visit the Site,
              the Cookie cannot be used by anyone but Google. Google's ability
              to use and share information collected by Google Analytics about
              your visits to the Site is restricted by the Google Analytics
              Terms of Use and the Google Privacy Policy. You may prevent your
              data from being used by Google Analytics by downloading and
              installing the Google Analytics Opt-out Browser Add-on, available
              at{" "}
              <a
                href="https://tools.google.com/dlpage/gaoptout/"
                target="_blank"
              >
                https://tools.google.com/dlpage/gaoptout/
              </a>
              . Mixpanel's ability to use and share information it collects
              about your visits to the Site is restricted by the Mixpanel Terms
              of Use located at{" "}
              <a href="https://mixpanel.com/terms/" target="_blank">
                https://mixpanel.com/terms/
              </a>{" "}
              and the Mixpanel Privacy Policy located at{" "}
              <a href="https://mixpanel.com/privacy/" target="_blank">
                https://mixpanel.com/privacy/
              </a>
              . You can opt-out of Mixpanel's automatic retention of data
              collected through your browsers while on our Site by clicking on
              this link{" "}
              <a href="https://mixpanel.com/optout/" target="_blank">
                https://mixpanel.com/optout/
              </a>
              . To track opt-outs, Mixpanel uses a persistent opt-out cookie
              placed on your devices. You can find out the types of information
              AppsFlyer collects and how you can opt-out of AppsFlyer by
              visiting its website:{" "}
              <a href="http://www.appsflyer.com/opt-out" target="_blank">
                http://www.appsflyer.com/opt-out
              </a>{" "}
              or by emailing privacy@appsflyer.com.
            </li>
          </ul>

          <h3>De-Identified Information.</h3>
          <p>
            We may use De-Identified Information created by us without
            restriction.
          </p>

          <h3>Information You Share With Third Parties.</h3>
          <p>
            This Site Privacy Policy applies only to information we collect
            through the Site and in email, text and other electronic
            communications set through or in connection with the Site. This Site
            Privacy Policy DOES NOT apply to information collected by any third
            party. When you click on links on the Site you may leave our Site.
            We are not responsible for the privacy practices of other sites, and
            we encourage you to read their privacy statements.
          </p>

          <h3>Modification of Information. </h3>
          <p>
            Members will be able to update some of their information through the
            Site. Requests to modify any information may also be submitted to{" "}
            <a href="mailto:support@swyftdoc.com" target="_blank">
              support@swyftdoc.com
            </a>
            .
          </p>

          <h3>Limitations on Deletion of Information.</h3>
          <p>
            You may request deletion of your Personal Information by us,
            however, we may be required (by law or otherwise) to keep this
            information and not delete it (or to keep this information for a
            certain time, in which case we will comply with your deletion
            request only after we have fulfilled such requirements). When we
            delete Personal Information, it will be deleted from the active
            database, but may remain in our archives and we may also retain
            Anonymous Information about your use of our services. Once we
            disclose some of your Personal Information to third parties, we may
            not be able to access that Personal Information any longer and
            cannot force the deletion or modification of any such information by
            the parties to whom we have made those disclosures. After we delete
            Personal Information, we may retain De-Identified Data and will
            continue to use De-Identified Data as permitted under this Site
            Privacy Policy.
          </p>

          <h3>Steps we take to keep your information secure.</h3>
          <p>
            We employ reasonable physical, electronic and managerial security
            methods to help protect against unauthorized access to Personal
            Information, such as encryption. But please be aware that no data
            transmission over the Internet or data storage facility can be
            guaranteed to be perfectly secure. As a result, while we try to
            protect your Personal Information, we cannot ensure or guarantee the
            security of any information you transmit to us.
          </p>

          <h3>Right to Non-Discrimination</h3>
          <p>
            SwyftDoc will not discriminate against you for exercising any of
            your privacy rights under law, or as set forth in this Site Privacy
            Policy.
          </p>

          <h3>Changes to the Site Privacy Policy. </h3>
          <p>
            We may change the Site Privacy Policy from time to time in the
            future. We will post any revised version of the Site Privacy Policy
            on this page. Continued use of our services following notice of such
            changes will indicate your acknowledgement of such changes and
            agreement to be bound by the terms and conditions of such changes.
            By using the Site, you are agreeing to our collection, use and
            disposal of Personal Information and other data as described in this
            Site Privacy Policy, both as it exists now and as it is changed from
            time to time.
          </p>

          <p>
            All capitalized terms in the SwyftDoc Privacy Policy (Sections I and
            II) not defined herein shall have the meaning set forth in the
            SwyftDoc Terms of Use.{" "}
          </p>

          <p>
            <i>
              If you have questions or concerns about our Privacy Practices, or
              would like to report a violation, please contact us by sending an
              email to{" "}
              <a href="mailto:compliancehotline@swyftdoc.com" target="_blank">
                compliancehotline@swyftdoc.com
              </a>
              .
            </i>
          </p>
        </li>
      </ol>
    </div>
  );
}
